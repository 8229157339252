<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="formData.status"
                :items="status"
                dense
                chips
                small-chips
                label="الحالة"
                item-text="title"
                item-value="value"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.code"
                :rules="textRules"
                label="الكود"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.user_max_num"
                :rules="textRules"
                label="أقصى عدد من المستخدمين"
                required
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.persentage"
                :rules="textRules"
                label="النسبة"
                required
              ></v-text-field>
            </v-col> -->

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="formData.is_persentage"
                :label="`هل نسبة مئوية: ${formData.is_persentage.toString()}`"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.value"
                :rules="textRules"
                label="القيمة"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="loadingTypes"
                v-model="formData.type_id"
                :items="types"
                dense
                chips
                small-chips
                label="النوع"
                item-text="title"
                item-value="id"
                @change="getcourses"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="loadingCourses"
                v-model="formData.courseid"
                :items="courses"
                dense
                chips
                small-chips
                label="الدورات"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col md="6">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.start_date"
                    label="تاريخ البداية"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.start_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col md="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.exp_date"
                    label="تاريخ الإنتهاء"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.exp_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="formData.is_super"
                :label="`سوبر: ${formData.is_super.toString()}`"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          إلغاء
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          إرسال
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          إغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "items", "collection"],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      menu1: false,
      menu2: false,
      loadingTypes: false,
      loadingCourses: false,
      status: [
        { title: "Published", value: "published" },
        { title: "Draft", value: "draft" },
        { title: "Free", value: "free" },
      ],
      formData: {
        id: null,
        is_persentage: false,
        code: null,
        courseid: null,
        type_id: null,
        user_max_num: 0,
        start_date: null,
        exp_date: null,
        persentage: null,
        value: null,
        is_super: false,
        status: null,
      },
      courses: [],
      types: [],
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async removeimg(id) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.formData["tag_image"] = null;
      this.submit();
    },
    async submit() {
      // console.log(this.background_color);
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          console.log(this.formData);
          if (this.record) {
            await ApiService.update(
              `items/${this.collection}`,
              this.formData.id,
              this.formData
            );
          } else {
            await ApiService.post(`items/${this.collection}`, this.formData);
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    async getcourses() {
      // console.log(this.formData);
      this.formData.courseid = null;
      try {
        this.loadingCourses = true;
        const { data } = await ApiService.get(
          `items/courses?fields=id,title&filter[type_id]=${this.formData.type_id}`
        );
        this.courses = data.data;
        this.loadingCourses = false;
      } catch (error) {
        console.log(error);
      }
    },

    async getTypes() {
      try {
        this.loadingTypes = true;
        const { data } = await ApiService.get("items/types?fields=id,title");
        this.types = data.data;
        this.loadingTypes = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.getTypes();
    if (this.record) {
      Object.keys(this.formData).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.formData[element] = this.record[element].id;
          } else {
            this.formData[element] = this.record[element];
          }
        }
      });
      this.getcourses();
      this.formData.courseid = this.record.courseid?.id;
    }
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
